import { createApp } from 'vue'

import generatedRoutes from 'virtual:generated-pages'
import { setupLayouts } from 'virtual:generated-layouts'
import { createRouter, createWebHistory } from 'vue-router'
import { createPinia } from 'pinia'
import { VueFire, VueFireAuthWithDependencies } from 'vuefire'
import type { PluginOptions } from 'vue-toastification'
import Toast, { POSITION } from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import { browserPopupRedirectResolver, indexedDBLocalPersistence } from 'firebase/auth'
import { initializeApp } from 'firebase/app'
import { createHead } from '@unhead/vue'
import App from './App.vue'
import 'virtual:uno.css'

import type { UserModule } from './types'
import Vue3LottiePlugin from '@/modules/vue3lottie'

const firebaseConfig = {
  apiKey: import.meta.env.VITE_APP_FIREBASE_API_KEY,
  authDomain: import.meta.env.VITE_APP_FIREBASE_AUTH_DOMAIN,
  projectId: import.meta.env.VITE_APP_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: import.meta.env.VITE_APP_FIREBASE_APP_ID,
}

init()

async function init() {
  const app = createApp(App)

  // Use separate Toast package
  const options: PluginOptions = {
    position: POSITION.BOTTOM_RIGHT,
    shareAppContext: true,
    timeout: 10000,
  }
  app.use(Toast, options)
  const head = createHead()
  app.use(head)

  app.use(Vue3LottiePlugin)

  const routes = setupLayouts(generatedRoutes)
  const router = createRouter({
    routes,
    history: createWebHistory(import.meta.env.BASE_URL),
  })
  router.onError((error, to) => {
    if (
      error.message.includes('Failed to fetch dynamically imported module')
      || error.message.includes('Importing a module script failed')
      || error.message.includes('error loading dynamically imported module')
    ) {
      console.info('Het portaal is ge-update')
      window.location.href = to.fullPath
    }
  })

  const pinia = createPinia()
  app.use(pinia)
  app.use(VueFire, {
    // imported above but could also just be created here
    firebaseApp: initializeApp(firebaseConfig),
    modules: [
      VueFireAuthWithDependencies({
        dependencies: {
          popupRedirectResolver: browserPopupRedirectResolver,
          persistence: indexedDBLocalPersistence,
        },
      }),
    ],
  })

  // Install modules in modules/ folder
  Object.values(import.meta.glob<{ install: UserModule }>('./modules/*.ts', { eager: true })).forEach(i => i.install?.(app, router))

  // Register the router and it's guards
  app.use(router)
  app.mount('#app')
}
