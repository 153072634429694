/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ItemFromJSON, ItemToJSON, } from './Item.js';
import { CharacteristicColorFromJSON, CharacteristicColorToJSON, } from './CharacteristicColor.js';
import { DecimalRangeDtoFromJSON, DecimalRangeDtoToJSON, } from './DecimalRangeDto.js';
import { NullableRangeDtoFromJSON, NullableRangeDtoToJSON, } from './NullableRangeDto.js';
import { PeriodicityFromJSON, PeriodicityToJSON, } from './Periodicity.js';
import { JobDescriptionDtoFromJSON, JobDescriptionDtoToJSON, } from './JobDescriptionDto.js';
import { PointDtoFromJSON, PointDtoToJSON, } from './PointDto.js';
import { RangeDtoFromJSON, RangeDtoToJSON, } from './RangeDto.js';
/**
 * Check if a given object implements the CreateJobCommandDto interface.
 */
export function instanceOfCreateJobCommandDto(value) {
    if (!('description' in value) || value['description'] === undefined)
        return false;
    if (!('toPublish' in value) || value['toPublish'] === undefined)
        return false;
    if (!('toSubscribe' in value) || value['toSubscribe'] === undefined)
        return false;
    if (!('geolocation' in value) || value['geolocation'] === undefined)
        return false;
    if (!('locationName' in value) || value['locationName'] === undefined)
        return false;
    if (!('isRemote' in value) || value['isRemote'] === undefined)
        return false;
    if (!('jobCategoryId' in value) || value['jobCategoryId'] === undefined)
        return false;
    if (!('jobFunctionId' in value) || value['jobFunctionId'] === undefined)
        return false;
    if (!('educationLevels' in value) || value['educationLevels'] === undefined)
        return false;
    if (!('yearsExperience' in value) || value['yearsExperience'] === undefined)
        return false;
    if (!('salary' in value) || value['salary'] === undefined)
        return false;
    if (!('salaryPeriodicity' in value) || value['salaryPeriodicity'] === undefined)
        return false;
    if (!('hoursPerWeek' in value) || value['hoursPerWeek'] === undefined)
        return false;
    if (!('preferredCharacteristicColor' in value) || value['preferredCharacteristicColor'] === undefined)
        return false;
    if (!('softSkills' in value) || value['softSkills'] === undefined)
        return false;
    if (!('hardSkills' in value) || value['hardSkills'] === undefined)
        return false;
    if (!('languages' in value) || value['languages'] === undefined)
        return false;
    return true;
}
export function CreateJobCommandDtoFromJSON(json) {
    return CreateJobCommandDtoFromJSONTyped(json, false);
}
export function CreateJobCommandDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'description': JobDescriptionDtoFromJSON(json['description']),
        'toPublish': json['toPublish'],
        'toSubscribe': json['toSubscribe'],
        'geolocation': PointDtoFromJSON(json['geolocation']),
        'locationName': json['locationName'],
        'isRemote': json['isRemote'],
        'jobCategoryId': json['jobCategoryId'],
        'jobFunctionId': json['jobFunctionId'],
        'educationLevels': (json['educationLevels'].map(ItemFromJSON)),
        'yearsExperience': NullableRangeDtoFromJSON(json['yearsExperience']),
        'salary': DecimalRangeDtoFromJSON(json['salary']),
        'salaryPeriodicity': PeriodicityFromJSON(json['salaryPeriodicity']),
        'hoursPerWeek': RangeDtoFromJSON(json['hoursPerWeek']),
        'preferredCharacteristicColor': CharacteristicColorFromJSON(json['preferredCharacteristicColor']),
        'softSkills': (json['softSkills'].map(ItemFromJSON)),
        'hardSkills': (json['hardSkills'].map(ItemFromJSON)),
        'languages': (json['languages'].map(ItemFromJSON)),
    };
}
export function CreateJobCommandDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'description': JobDescriptionDtoToJSON(value['description']),
        'toPublish': value['toPublish'],
        'toSubscribe': value['toSubscribe'],
        'geolocation': PointDtoToJSON(value['geolocation']),
        'locationName': value['locationName'],
        'isRemote': value['isRemote'],
        'jobCategoryId': value['jobCategoryId'],
        'jobFunctionId': value['jobFunctionId'],
        'educationLevels': (value['educationLevels'].map(ItemToJSON)),
        'yearsExperience': NullableRangeDtoToJSON(value['yearsExperience']),
        'salary': DecimalRangeDtoToJSON(value['salary']),
        'salaryPeriodicity': PeriodicityToJSON(value['salaryPeriodicity']),
        'hoursPerWeek': RangeDtoToJSON(value['hoursPerWeek']),
        'preferredCharacteristicColor': CharacteristicColorToJSON(value['preferredCharacteristicColor']),
        'softSkills': (value['softSkills'].map(ItemToJSON)),
        'hardSkills': (value['hardSkills'].map(ItemToJSON)),
        'languages': (value['languages'].map(ItemToJSON)),
    };
}
