/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the ContactRequestCommand interface.
 */
export function instanceOfContactRequestCommand(value) {
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('companyName' in value) || value['companyName'] === undefined)
        return false;
    if (!('email' in value) || value['email'] === undefined)
        return false;
    if (!('phoneNumber' in value) || value['phoneNumber'] === undefined)
        return false;
    return true;
}
export function ContactRequestCommandFromJSON(json) {
    return ContactRequestCommandFromJSONTyped(json, false);
}
export function ContactRequestCommandFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'name': json['name'],
        'companyName': json['companyName'],
        'email': json['email'],
        'phoneNumber': json['phoneNumber'],
    };
}
export function ContactRequestCommandToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'name': value['name'],
        'companyName': value['companyName'],
        'email': value['email'],
        'phoneNumber': value['phoneNumber'],
    };
}
