import type {
  GetCompanyStatisticsResult,
  ListUnhandledJobsJobDto,
  ListUnhandledMatchesMatchDto,
} from '@swipe4work/api-client-fetch'
import { EmployerApi } from '@swipe4work/api-client-fetch'
import { defineStore } from 'pinia'
import { useToast } from 'vue-toastification'
import { fetchConfiguration } from '@/modules/http'

export const useDashboardStore = defineStore('dashboard', {
  state: () => ({
    api: new EmployerApi(fetchConfiguration),
    statistics: undefined as GetCompanyStatisticsResult | undefined,
    unhandledMatches: [] as ListUnhandledMatchesMatchDto[],
    unhandledJobs: [] as ListUnhandledJobsJobDto[],
    toast: useToast(),
    t: useI18n(),
  }),
  actions: {
    async fill(): Promise<void> {
      await Promise.all([this.fetchUnhandledJobs(), this.fetchUnhandledMatches(), this.fetchStatistics()])
    },
    async fetchUnhandledMatches(): Promise<ListUnhandledMatchesMatchDto[]> {
      const response = await this.api.listUnhandledMatches(10, 0)
      this.unhandledMatches = response.matches
      return this.unhandledMatches
    },
    async fetchUnhandledJobs(): Promise<ListUnhandledJobsJobDto[]> {
      const response = await this.api.listUnhandledJobs(10, 0)
      this.unhandledJobs = response.jobs
      return this.unhandledJobs
    },
    async fetchStatistics(): Promise<GetCompanyStatisticsResult> {
      const response = await this.api.getStatistics()
      this.statistics = response
      return this.statistics
    },
  },
})
