/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime.js';
import { AddOnboardingWorkExperienceCommandDtoToJSON, ContactMeCommandToJSON, ContactRequestCommandToJSON, GetAutoCompleteLocationsResultFromJSON, GetLocationDetailsResultFromJSON, GetLocationGeocodeResultFromJSON, GetRoleResultFromJSON, JobCategoryCrudJobCategoryDtoFromJSON, JobCategoryCrudListResultFromJSON, JobFunctionCrudCreateOrUpdateCommandToJSON, JobFunctionCrudJobFunctionDtoFromJSON, JobFunctionCrudListResultFromJSON, ListEducationLevelsResultFromJSON, ListInterestsResultFromJSON, ListNewJobReasonsResultFromJSON, ListPersonalCharacteristicsResultFromJSON, ListPersonalityTypesResultFromJSON, ListSkillsForInterestsResultFromJSON, ListSkillsForJobCategoriesResultFromJSON, SubscribeToNewsletterCommandToJSON, UpdateOnboardingNoWorkExperienceCommandDtoToJSON, WorkExperienceDtoFromJSON, WorkExperienceDtoToJSON, } from '../models/index.js';
/**
 *
 */
export class GeneralApi extends runtime.BaseAPI {
    /**
     * Add a new work experience for the currently logged in user
     */
    async addOnboardingWorkExperienceRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/user/onboarding/work-experience`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddOnboardingWorkExperienceCommandDtoToJSON(requestParameters['addOnboardingWorkExperienceCommandDto']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => WorkExperienceDtoFromJSON(jsonValue));
    }
    /**
     * Add a new work experience for the currently logged in user
     */
    async addOnboardingWorkExperience(addOnboardingWorkExperienceCommandDto, initOverrides) {
        const response = await this.addOnboardingWorkExperienceRaw({ addOnboardingWorkExperienceCommandDto: addOnboardingWorkExperienceCommandDto }, initOverrides);
        return await response.value();
    }
    /**
     * Contact Me sends an email with body to the info mail address
     */
    async contactMeRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/contact`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactMeCommandToJSON(requestParameters['contactMeCommand']),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Contact Me sends an email with body to the info mail address
     */
    async contactMe(contactMeCommand, initOverrides) {
        await this.contactMeRaw({ contactMeCommand: contactMeCommand }, initOverrides);
    }
    /**
     * Sends an email with contact information to the info mail address
     */
    async contactRequestRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/contact-request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ContactRequestCommandToJSON(requestParameters['contactRequestCommand']),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Sends an email with contact information to the info mail address
     */
    async contactRequest(contactRequestCommand, initOverrides) {
        await this.contactRequestRaw({ contactRequestCommand: contactRequestCommand }, initOverrides);
    }
    /**
     */
    async createJobFunctionRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/job-function`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: JobFunctionCrudCreateOrUpdateCommandToJSON(requestParameters['jobFunctionCrudCreateOrUpdateCommand']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => JobFunctionCrudJobFunctionDtoFromJSON(jsonValue));
    }
    /**
     */
    async createJobFunction(jobFunctionCrudCreateOrUpdateCommand, initOverrides) {
        const response = await this.createJobFunctionRaw({ jobFunctionCrudCreateOrUpdateCommand: jobFunctionCrudCreateOrUpdateCommand }, initOverrides);
        return await response.value();
    }
    /**
     * Delete a work experience for the currently logged in user
     */
    async deleteOnboardingWorkExperienceRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling deleteOnboardingWorkExperience().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/user/onboarding/work-experience/{Id}`.replace(`{${"Id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Delete a work experience for the currently logged in user
     */
    async deleteOnboardingWorkExperience(id, initOverrides) {
        await this.deleteOnboardingWorkExperienceRaw({ id: id }, initOverrides);
    }
    /**
     * Get Auto complete locations from the Google Places API.
     */
    async getAutoCompleteLocationsRaw(requestParameters, initOverrides) {
        if (requestParameters['input'] == null) {
            throw new runtime.RequiredError('input', 'Required parameter "input" was null or undefined when calling getAutoCompleteLocations().');
        }
        const queryParameters = {};
        if (requestParameters['input'] != null) {
            queryParameters['Input'] = requestParameters['input'];
        }
        if (requestParameters['sessionToken'] != null) {
            queryParameters['SessionToken'] = requestParameters['sessionToken'];
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/location/auto-complete`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetAutoCompleteLocationsResultFromJSON(jsonValue));
    }
    /**
     * Get Auto complete locations from the Google Places API.
     */
    async getAutoCompleteLocations(input, sessionToken, initOverrides) {
        const response = await this.getAutoCompleteLocationsRaw({ input: input, sessionToken: sessionToken }, initOverrides);
        return await response.value();
    }
    /**
     * List a job category, along with their job functions and skills
     */
    async getJobCategoryRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getJobCategory().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/job-category/v2/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => JobCategoryCrudJobCategoryDtoFromJSON(jsonValue));
    }
    /**
     * List a job category, along with their job functions and skills
     */
    async getJobCategory(id, initOverrides) {
        const response = await this.getJobCategoryRaw({ id: id }, initOverrides);
        return await response.value();
    }
    /**
     */
    async getJobFunctionRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getJobFunction().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/job-function/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => JobFunctionCrudJobFunctionDtoFromJSON(jsonValue));
    }
    /**
     */
    async getJobFunction(id, initOverrides) {
        const response = await this.getJobFunctionRaw({ id: id }, initOverrides);
        return await response.value();
    }
    /**
     * Get location details (including Lat, Long) from the Google Places API based on the place Id.
     */
    async getLocationDetailsRaw(requestParameters, initOverrides) {
        if (requestParameters['sessionToken'] == null) {
            throw new runtime.RequiredError('sessionToken', 'Required parameter "sessionToken" was null or undefined when calling getLocationDetails().');
        }
        if (requestParameters['placeId'] == null) {
            throw new runtime.RequiredError('placeId', 'Required parameter "placeId" was null or undefined when calling getLocationDetails().');
        }
        const queryParameters = {};
        if (requestParameters['sessionToken'] != null) {
            queryParameters['SessionToken'] = requestParameters['sessionToken'];
        }
        if (requestParameters['placeId'] != null) {
            queryParameters['PlaceId'] = requestParameters['placeId'];
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/location/details`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetLocationDetailsResultFromJSON(jsonValue));
    }
    /**
     * Get location details (including Lat, Long) from the Google Places API based on the place Id.
     */
    async getLocationDetails(sessionToken, placeId, initOverrides) {
        const response = await this.getLocationDetailsRaw({ sessionToken: sessionToken, placeId: placeId }, initOverrides);
        return await response.value();
    }
    /**
     * Get a location geocode (including Lat, Long) from the Google Places API based on the lat long.
     */
    async getLocationGeocodeRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters['latitude'] != null) {
            queryParameters['latitude'] = requestParameters['latitude'];
        }
        if (requestParameters['longitude'] != null) {
            queryParameters['longitude'] = requestParameters['longitude'];
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/location/location-geocode`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetLocationGeocodeResultFromJSON(jsonValue));
    }
    /**
     * Get a location geocode (including Lat, Long) from the Google Places API based on the lat long.
     */
    async getLocationGeocode(latitude, longitude, initOverrides) {
        const response = await this.getLocationGeocodeRaw({ latitude: latitude, longitude: longitude }, initOverrides);
        return await response.value();
    }
    /**
     * Get the role of the current user.
     */
    async getRoleRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/user/account/role`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetRoleResultFromJSON(jsonValue));
    }
    /**
     * Get the role of the current user.
     */
    async getRole(initOverrides) {
        const response = await this.getRoleRaw(initOverrides);
        return await response.value();
    }
    /**
     * List all education levels
     */
    async listEducationLevelsRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/education-level`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListEducationLevelsResultFromJSON(jsonValue));
    }
    /**
     * List all education levels
     */
    async listEducationLevels(initOverrides) {
        const response = await this.listEducationLevelsRaw(initOverrides);
        return await response.value();
    }
    /**
     * List all interests
     */
    async listInterestsRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/interest`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListInterestsResultFromJSON(jsonValue));
    }
    /**
     * List all interests
     */
    async listInterests(initOverrides) {
        const response = await this.listInterestsRaw(initOverrides);
        return await response.value();
    }
    /**
     * List all job categories, along with their job functions and skills
     */
    async listJobCategoriesRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/job-category/v2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => JobCategoryCrudListResultFromJSON(jsonValue));
    }
    /**
     * List all job categories, along with their job functions and skills
     */
    async listJobCategories(initOverrides) {
        const response = await this.listJobCategoriesRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async listJobFunctionsRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/job-function`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(JobFunctionCrudListResultFromJSON));
    }
    /**
     */
    async listJobFunctions(initOverrides) {
        const response = await this.listJobFunctionsRaw(initOverrides);
        return await response.value();
    }
    /**
     * List all new job reasons
     */
    async listNewJobReasonsRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/new-job-reasons`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListNewJobReasonsResultFromJSON(jsonValue));
    }
    /**
     * List all new job reasons
     */
    async listNewJobReasons(initOverrides) {
        const response = await this.listNewJobReasonsRaw(initOverrides);
        return await response.value();
    }
    /**
     * List all personal characteristics
     */
    async listPersonalCharacteristicsRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/personal-characteristics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListPersonalCharacteristicsResultFromJSON(jsonValue));
    }
    /**
     * List all personal characteristics
     */
    async listPersonalCharacteristics(initOverrides) {
        const response = await this.listPersonalCharacteristicsRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async listPersonalityTypesRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/personality-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListPersonalityTypesResultFromJSON(jsonValue));
    }
    /**
     */
    async listPersonalityTypes(initOverrides) {
        const response = await this.listPersonalityTypesRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async listSkillsForInterestsRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters['interestIds'] != null) {
            queryParameters['interestIds'] = requestParameters['interestIds'];
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/skill/v2/list-for-interests`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListSkillsForInterestsResultFromJSON(jsonValue));
    }
    /**
     */
    async listSkillsForInterests(interestIds, initOverrides) {
        const response = await this.listSkillsForInterestsRaw({ interestIds: interestIds }, initOverrides);
        return await response.value();
    }
    /**
     */
    async listSkillsForJobCategoriesRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters['jobCategoryIds'] != null) {
            queryParameters['jobCategoryIds'] = requestParameters['jobCategoryIds'];
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/skill/v2/list-for-job-categories`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListSkillsForJobCategoriesResultFromJSON(jsonValue));
    }
    /**
     */
    async listSkillsForJobCategories(jobCategoryIds, initOverrides) {
        const response = await this.listSkillsForJobCategoriesRaw({ jobCategoryIds: jobCategoryIds }, initOverrides);
        return await response.value();
    }
    /**
     * Subscribe to newsletter adds an email address in the database.
     */
    async subscribeToNewsletterRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/subscribe`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SubscribeToNewsletterCommandToJSON(requestParameters['subscribeToNewsletterCommand']),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Subscribe to newsletter adds an email address in the database.
     */
    async subscribeToNewsletter(subscribeToNewsletterCommand, initOverrides) {
        await this.subscribeToNewsletterRaw({ subscribeToNewsletterCommand: subscribeToNewsletterCommand }, initOverrides);
    }
    /**
     */
    async updateJobFunctionRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling updateJobFunction().');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/job-function/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: JobFunctionCrudCreateOrUpdateCommandToJSON(requestParameters['jobFunctionCrudCreateOrUpdateCommand']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => JobFunctionCrudJobFunctionDtoFromJSON(jsonValue));
    }
    /**
     */
    async updateJobFunction(id, jobFunctionCrudCreateOrUpdateCommand, initOverrides) {
        const response = await this.updateJobFunctionRaw({ id: id, jobFunctionCrudCreateOrUpdateCommand: jobFunctionCrudCreateOrUpdateCommand }, initOverrides);
        return await response.value();
    }
    /**
     * Set the No work experience boolean to true/false
     */
    async updateOnboardingNoWorkExperienceRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/user/onboarding/work-experience/none`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateOnboardingNoWorkExperienceCommandDtoToJSON(requestParameters['updateOnboardingNoWorkExperienceCommandDto']),
        }, initOverrides);
        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse(response);
        }
        else {
            return new runtime.TextApiResponse(response);
        }
    }
    /**
     * Set the No work experience boolean to true/false
     */
    async updateOnboardingNoWorkExperience(updateOnboardingNoWorkExperienceCommandDto, initOverrides) {
        const response = await this.updateOnboardingNoWorkExperienceRaw({ updateOnboardingNoWorkExperienceCommandDto: updateOnboardingNoWorkExperienceCommandDto }, initOverrides);
        return await response.value();
    }
    /**
     * Update a work experience for the currently logged in user
     */
    async updateOnboardingWorkExperienceRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/user/onboarding/work-experience`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: WorkExperienceDtoToJSON(requestParameters['workExperienceDto']),
        }, initOverrides);
        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse(response);
        }
        else {
            return new runtime.TextApiResponse(response);
        }
    }
    /**
     * Update a work experience for the currently logged in user
     */
    async updateOnboardingWorkExperience(workExperienceDto, initOverrides) {
        const response = await this.updateOnboardingWorkExperienceRaw({ workExperienceDto: workExperienceDto }, initOverrides);
        return await response.value();
    }
}
