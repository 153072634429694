/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ListSubscriptionQuestionAnswersSubscriptionQuestionAnswerDtoFromJSON, ListSubscriptionQuestionAnswersSubscriptionQuestionAnswerDtoToJSON, } from './ListSubscriptionQuestionAnswersSubscriptionQuestionAnswerDto.js';
/**
 * Check if a given object implements the ListSubscriptionQuestionAnswersResult interface.
 */
export function instanceOfListSubscriptionQuestionAnswersResult(value) {
    if (!('questions' in value) || value['questions'] === undefined)
        return false;
    return true;
}
export function ListSubscriptionQuestionAnswersResultFromJSON(json) {
    return ListSubscriptionQuestionAnswersResultFromJSONTyped(json, false);
}
export function ListSubscriptionQuestionAnswersResultFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'questions': (json['questions'].map(ListSubscriptionQuestionAnswersSubscriptionQuestionAnswerDtoFromJSON)),
    };
}
export function ListSubscriptionQuestionAnswersResultToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'questions': (value['questions'].map(ListSubscriptionQuestionAnswersSubscriptionQuestionAnswerDtoToJSON)),
    };
}
