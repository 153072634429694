import { defineStore } from 'pinia'
import { sendPasswordResetEmail, signInWithCustomToken, signInWithEmailAndPassword, updatePassword } from 'firebase/auth'
import { GeneralApi, Role } from '@swipe4work/api-client-fetch'
import type { FirebaseError } from 'firebase/app'
import { getCurrentUser, useFirebaseAuth } from 'vuefire'
import { useToast } from 'vue-toastification'
import { fetchConfiguration } from '@/modules/http'

export const useAuthStore = defineStore('auth', {
  state: () => ({
    generalApi: new GeneralApi(fetchConfiguration),
    router: useRouter(),
    t: useI18n(),
    role: undefined as Role | undefined,
    authError: undefined as Error | undefined,
    firebaseAuth: useFirebaseAuth(),
  }),
  getters: {
    isEmployeeUser: state => (state.role && state.role == Role.User) || state.role == Role.OnboardingUser,
  },
  actions: {
    async getCurrentUser() {
      return await getCurrentUser()
    },
    async getRole() {
      if (this.role) {
        return this.role
      }

      return await this.fetchRole()
    },
    async fetchRole() {
      try {
        const roleResponse = await this.generalApi.getRole()
        this.role = roleResponse.role
      } catch (e) {
        const toast = useToast()
        toast.error(this.t.t('general.initialLoadFailed'), { timeout: undefined })
      }
      return this.role
    },
    async login(email: string, password: string): Promise<boolean> {
      try {
        const response = await signInWithEmailAndPassword(this.firebaseAuth!, email, password)
        if (!response) {
          this.authError = new Error('login failed')
          return false
        }
        return true
      } catch (Error) {
        return false
      }
    },

    async loginWithToken(token: string): Promise<boolean> {
      try {
        const response = await signInWithCustomToken(this.firebaseAuth!, token)
        if (!response) {
          this.authError = new Error('login failed')
          return false
        }
        return true
      } catch (Error) {
        return false
      }
    },
    async sendResetPasswordMail(email: string): Promise<FirebaseError | null> {
      try {
        await sendPasswordResetEmail(this.firebaseAuth!, email)
      } catch (e: unknown) {
        return e as FirebaseError
      }
      return null
    },
    async updatePassword(email: string, currentPassword: string, newPassword: string): Promise<FirebaseError | null> {
      try {
        const user = await signInWithEmailAndPassword(this.firebaseAuth!, email, currentPassword)
        await updatePassword(user.user, newPassword)
      } catch (e: unknown) {
        return e as FirebaseError
      }
      return null
    },
    async signOut() {
      await this.firebaseAuth!.signOut()
      this.role = undefined
      this.router.push({ name: 'login' })
    },
  },
})
