import posthog from 'posthog-js'

import type { UserModule } from '@/types'

// Install and configure Posthog: https://posthog.com/docs/libraries/vue-js#method-2-use-provide--inject
export const install: UserModule = (app, router) => {
  posthog.init(import.meta.env.VITE_APP_POSTHOG_TOKEN, {
    api_host: 'https://ph.swipe4work.com',
    person_profiles: 'identified_only',
    //
    capture_pageview: false,
  })
  app.provide('posthog', posthog)

  // Manually captue pageviews
  router.afterEach((to, form, failure) => {
    if (!failure) {
      nextTick(() => {
        posthog.capture('$pageview', { path: to.fullPath })
      })
    }
  })
}
