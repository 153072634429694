import { breakpointsTailwind, useBreakpoints } from '@vueuse/core'

// Small wrapper around useBreakpoints to provide presets and more readable names
export function useResponsive() {
  const breakpoints = useBreakpoints(breakpointsTailwind)
  const isMobile = breakpoints.smaller('lg')
  const isDesktop = breakpoints.greaterOrEqual('lg')

  return {
    breakpoints,
    isMobile,
    isDesktop,
  }
}
