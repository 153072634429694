/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { ItemFromJSON, ItemToJSON, } from './Item.js';
import { PeriodicityFromJSON, PeriodicityToJSON, } from './Periodicity.js';
/**
 * Check if a given object implements the JobCategoryCrudJobCategoryDto interface.
 */
export function instanceOfJobCategoryCrudJobCategoryDto(value) {
    if (!('id' in value) || value['id'] === undefined)
        return false;
    if (!('name' in value) || value['name'] === undefined)
        return false;
    if (!('defaultSalaryPeriodicity' in value) || value['defaultSalaryPeriodicity'] === undefined)
        return false;
    if (!('salaryConversionFactor' in value) || value['salaryConversionFactor'] === undefined)
        return false;
    if (!('jobFunctions' in value) || value['jobFunctions'] === undefined)
        return false;
    return true;
}
export function JobCategoryCrudJobCategoryDtoFromJSON(json) {
    return JobCategoryCrudJobCategoryDtoFromJSONTyped(json, false);
}
export function JobCategoryCrudJobCategoryDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'id': json['id'],
        'name': json['name'],
        'defaultSalaryPeriodicity': PeriodicityFromJSON(json['defaultSalaryPeriodicity']),
        'salaryConversionFactor': json['salaryConversionFactor'],
        'jobFunctions': (json['jobFunctions'].map(ItemFromJSON)),
    };
}
export function JobCategoryCrudJobCategoryDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'id': value['id'],
        'name': value['name'],
        'defaultSalaryPeriodicity': PeriodicityToJSON(value['defaultSalaryPeriodicity']),
        'salaryConversionFactor': value['salaryConversionFactor'],
        'jobFunctions': (value['jobFunctions'].map(ItemToJSON)),
    };
}
