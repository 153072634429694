/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as runtime from '../runtime.js';
import { AddOrUpdateDefaultMessageCommandDtoToJSON, AddOrUpdateDefaultMessageResultFromJSON, BuyCreditsCommandDtoToJSON, CloseJobCommandDtoToJSON, CloseMatchCommandDtoToJSON, CreateCompanyForEmployerCommandDtoToJSON, CreateCompanyForEmployerResultFromJSON, CreateEmployerCommandDtoToJSON, CreateEmployerInviteCommandDtoToJSON, CreateEmployerResponseFromJSON, CreateJobCommandDtoToJSON, CreateTeammateInviteCommandDtoToJSON, DeleteDefaultMessageResultFromJSON, FindBestCandidateResultFromJSON, GetChatMessagesResultFromJSON, GetCompanyResultFromJSON, GetCompanyStatisticsResultFromJSON, GetEmployerProfileResultFromJSON, GetInvoiceFileUrlResultFromJSON, GetJobApplicationResultFromJSON, GetJobResultFromJSON, GetMatchResultFromJSON, GetMatchedUserResultFromJSON, GetSubscriptionResultFromJSON, ImageDtoFromJSON, ListAllMatchesResultFromJSON, ListAllSkillsResultFromJSON, ListAllWaitingJobApplicationsResultFromJSON, ListCreditTransactionsResultFromJSON, ListInvoicesForCompanyResultFromJSON, ListJobApplicationsForJobResultFromJSON, ListJobsResultFromJSON, ListManagedCompaniesResultFromJSON, ListMatchesForJobResultFromJSON, ListOtherEmployersForCompanyResultFromJSON, ListPaymentOptionsResultFromJSON, ListReferralInvitesResultFromJSON, ListSubscriptionQuestionAnswersResultFromJSON, ListUnhandledJobsResultFromJSON, ListUnhandledMatchesResultFromJSON, ReactToCandidateCommandDtoToJSON, RejectJobApplicationResultFromJSON, SendCompleteProfileRequestCommandDtoToJSON, SendMessageCommandDtoToJSON, SendMessageResponseFromJSON, SendPollCommandDtoToJSON, SendPollResultFromJSON, StartMatchCommandDtoToJSON, StartMatchResultFromJSON, SubscribeToJobCommandDtoToJSON, UpdateCompanyCommandDtoToJSON, UpdateEmployerProfileCommandDtoToJSON, UpdateJobCommandDtoToJSON, UpdateJobPublishedCommandDtoToJSON, UpdateManagedCompanyCommandDtoToJSON, } from '../models/index.js';
/**
 *
 */
export class EmployerApi extends runtime.BaseAPI {
    /**
     */
    async addOrUpdateDefaultMessageRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/company/default-messages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AddOrUpdateDefaultMessageCommandDtoToJSON(requestParameters['addOrUpdateDefaultMessageCommandDto']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => AddOrUpdateDefaultMessageResultFromJSON(jsonValue));
    }
    /**
     */
    async addOrUpdateDefaultMessage(addOrUpdateDefaultMessageCommandDto, initOverrides) {
        const response = await this.addOrUpdateDefaultMessageRaw({ addOrUpdateDefaultMessageCommandDto: addOrUpdateDefaultMessageCommandDto }, initOverrides);
        return await response.value();
    }
    /**
     */
    async buyCreditsRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/invoice/credits`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BuyCreditsCommandDtoToJSON(requestParameters['buyCreditsCommandDto']),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async buyCredits(buyCreditsCommandDto, initOverrides) {
        await this.buyCreditsRaw({ buyCreditsCommandDto: buyCreditsCommandDto }, initOverrides);
    }
    /**
     * Close a Job, this will make it unavailable for new matches
     */
    async closeJobRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/job/close`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: CloseJobCommandDtoToJSON(requestParameters['closeJobCommandDto']),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Close a Job, this will make it unavailable for new matches
     */
    async closeJob(closeJobCommandDto, initOverrides) {
        await this.closeJobRaw({ closeJobCommandDto: closeJobCommandDto }, initOverrides);
    }
    /**
     */
    async closeMatchRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/match/close`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CloseMatchCommandDtoToJSON(requestParameters['closeMatchCommandDto']),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     */
    async closeMatch(closeMatchCommandDto, initOverrides) {
        await this.closeMatchRaw({ closeMatchCommandDto: closeMatchCommandDto }, initOverrides);
    }
    /**
     */
    async createCompanyForEmployerRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/user/employer-onboarding/create-company`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateCompanyForEmployerCommandDtoToJSON(requestParameters['createCompanyForEmployerCommandDto']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateCompanyForEmployerResultFromJSON(jsonValue));
    }
    /**
     */
    async createCompanyForEmployer(createCompanyForEmployerCommandDto, initOverrides) {
        const response = await this.createCompanyForEmployerRaw({ createCompanyForEmployerCommandDto: createCompanyForEmployerCommandDto }, initOverrides);
        return await response.value();
    }
    /**
     */
    async createEmployerRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/user/employer-onboarding/register`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateEmployerCommandDtoToJSON(requestParameters['createEmployerCommandDto']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateEmployerResponseFromJSON(jsonValue));
    }
    /**
     */
    async createEmployer(createEmployerCommandDto, initOverrides) {
        const response = await this.createEmployerRaw({ createEmployerCommandDto: createEmployerCommandDto }, initOverrides);
        return await response.value();
    }
    /**
     */
    async createEmployerInviteRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/admin/invite/create-employer-invite/employer`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateEmployerInviteCommandDtoToJSON(requestParameters['createEmployerInviteCommandDto']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     */
    async createEmployerInvite(createEmployerInviteCommandDto, initOverrides) {
        const response = await this.createEmployerInviteRaw({ createEmployerInviteCommandDto: createEmployerInviteCommandDto }, initOverrides);
        return await response.value();
    }
    /**
     * Create a Job post
     */
    async createJobRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/job`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateJobCommandDtoToJSON(requestParameters['createJobCommandDto']),
        }, initOverrides);
        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse(response);
        }
        else {
            return new runtime.TextApiResponse(response);
        }
    }
    /**
     * Create a Job post
     */
    async createJob(createJobCommandDto, initOverrides) {
        const response = await this.createJobRaw({ createJobCommandDto: createJobCommandDto }, initOverrides);
        return await response.value();
    }
    /**
     */
    async createTeammateInviteRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/admin/invite/create-teammate-invite/teammate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateTeammateInviteCommandDtoToJSON(requestParameters['createTeammateInviteCommandDto']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     */
    async createTeammateInvite(createTeammateInviteCommandDto, initOverrides) {
        const response = await this.createTeammateInviteRaw({ createTeammateInviteCommandDto: createTeammateInviteCommandDto }, initOverrides);
        return await response.value();
    }
    /**
     */
    async deleteDefaultMessageRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling deleteDefaultMessage().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/company/default-messages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => DeleteDefaultMessageResultFromJSON(jsonValue));
    }
    /**
     */
    async deleteDefaultMessage(id, initOverrides) {
        const response = await this.deleteDefaultMessageRaw({ id: id }, initOverrides);
        return await response.value();
    }
    /**
     * Find the best candidate for the specified job
     */
    async findBestCandidateRaw(requestParameters, initOverrides) {
        if (requestParameters['jobId'] == null) {
            throw new runtime.RequiredError('jobId', 'Required parameter "jobId" was null or undefined when calling findBestCandidate().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/job/{jobId}/find-best-candidate`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters['jobId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => FindBestCandidateResultFromJSON(jsonValue));
    }
    /**
     * Find the best candidate for the specified job
     */
    async findBestCandidate(jobId, initOverrides) {
        const response = await this.findBestCandidateRaw({ jobId: jobId }, initOverrides);
        return await response.value();
    }
    /**
     */
    async getChatRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getChat().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/match/{id}/chat`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetChatMessagesResultFromJSON(jsonValue));
    }
    /**
     */
    async getChat(id, initOverrides) {
        const response = await this.getChatRaw({ id: id }, initOverrides);
        return await response.value();
    }
    /**
     */
    async getInvoiceFileUrlRaw(requestParameters, initOverrides) {
        if (requestParameters['fileId'] == null) {
            throw new runtime.RequiredError('fileId', 'Required parameter "fileId" was null or undefined when calling getInvoiceFileUrl().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/invoice/{fileId}`.replace(`{${"fileId"}}`, encodeURIComponent(String(requestParameters['fileId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetInvoiceFileUrlResultFromJSON(jsonValue));
    }
    /**
     */
    async getInvoiceFileUrl(fileId, initOverrides) {
        const response = await this.getInvoiceFileUrlRaw({ fileId: fileId }, initOverrides);
        return await response.value();
    }
    /**
     */
    async getJobRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getJob().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/job/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetJobResultFromJSON(jsonValue));
    }
    /**
     */
    async getJob(id, initOverrides) {
        const response = await this.getJobRaw({ id: id }, initOverrides);
        return await response.value();
    }
    /**
     * Get a specific job application
     */
    async getJobApplicationRaw(requestParameters, initOverrides) {
        if (requestParameters['jobApplicationId'] == null) {
            throw new runtime.RequiredError('jobApplicationId', 'Required parameter "jobApplicationId" was null or undefined when calling getJobApplication().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/job-application/{jobApplicationId}`.replace(`{${"jobApplicationId"}}`, encodeURIComponent(String(requestParameters['jobApplicationId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetJobApplicationResultFromJSON(jsonValue));
    }
    /**
     * Get a specific job application
     */
    async getJobApplication(jobApplicationId, initOverrides) {
        const response = await this.getJobApplicationRaw({ jobApplicationId: jobApplicationId }, initOverrides);
        return await response.value();
    }
    /**
     */
    async getMatchRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getMatch().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/match/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetMatchResultFromJSON(jsonValue));
    }
    /**
     */
    async getMatch(id, initOverrides) {
        const response = await this.getMatchRaw({ id: id }, initOverrides);
        return await response.value();
    }
    /**
     */
    async getMatchedUserRaw(requestParameters, initOverrides) {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError('id', 'Required parameter "id" was null or undefined when calling getMatchedUser().');
        }
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/match/{id}/user`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetMatchedUserResultFromJSON(jsonValue));
    }
    /**
     */
    async getMatchedUser(id, initOverrides) {
        const response = await this.getMatchedUserRaw({ id: id }, initOverrides);
        return await response.value();
    }
    /**
     */
    async getMyCompanyRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/company`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetCompanyResultFromJSON(jsonValue));
    }
    /**
     */
    async getMyCompany(initOverrides) {
        const response = await this.getMyCompanyRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async getProfileRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/profile`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetEmployerProfileResultFromJSON(jsonValue));
    }
    /**
     */
    async getProfile(initOverrides) {
        const response = await this.getProfileRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async getStatisticsRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/dashboard/statistics`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetCompanyStatisticsResultFromJSON(jsonValue));
    }
    /**
     */
    async getStatistics(initOverrides) {
        const response = await this.getStatisticsRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async getSubscriptionRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/invoice/subscription`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetSubscriptionResultFromJSON(jsonValue));
    }
    /**
     */
    async getSubscription(initOverrides) {
        const response = await this.getSubscriptionRaw(initOverrides);
        return await response.value();
    }
    /**
     * List all matches for the current company
     */
    async listAllMatchesRaw(requestParameters, initOverrides) {
        if (requestParameters['showClosed'] == null) {
            throw new runtime.RequiredError('showClosed', 'Required parameter "showClosed" was null or undefined when calling listAllMatches().');
        }
        const queryParameters = {};
        if (requestParameters['showClosed'] != null) {
            queryParameters['showClosed'] = requestParameters['showClosed'];
        }
        if (requestParameters['limit'] != null) {
            queryParameters['Limit'] = requestParameters['limit'];
        }
        if (requestParameters['offset'] != null) {
            queryParameters['Offset'] = requestParameters['offset'];
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/match`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListAllMatchesResultFromJSON(jsonValue));
    }
    /**
     * List all matches for the current company
     */
    async listAllMatches(showClosed, limit, offset, initOverrides) {
        const response = await this.listAllMatchesRaw({ showClosed: showClosed, limit: limit, offset: offset }, initOverrides);
        return await response.value();
    }
    /**
     */
    async listAllSkillsRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/skill/v2`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListAllSkillsResultFromJSON(jsonValue));
    }
    /**
     */
    async listAllSkills(initOverrides) {
        const response = await this.listAllSkillsRaw(initOverrides);
        return await response.value();
    }
    /**
     * List all waiting job applications for the current company
     */
    async listAllWaitingJobApplicationsRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters['limit'] != null) {
            queryParameters['Limit'] = requestParameters['limit'];
        }
        if (requestParameters['offset'] != null) {
            queryParameters['Offset'] = requestParameters['offset'];
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/job-application`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListAllWaitingJobApplicationsResultFromJSON(jsonValue));
    }
    /**
     * List all waiting job applications for the current company
     */
    async listAllWaitingJobApplications(limit, offset, initOverrides) {
        const response = await this.listAllWaitingJobApplicationsRaw({ limit: limit, offset: offset }, initOverrides);
        return await response.value();
    }
    /**
     */
    async listCreditTransactionsRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/invoice/credits`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListCreditTransactionsResultFromJSON(jsonValue));
    }
    /**
     */
    async listCreditTransactions(initOverrides) {
        const response = await this.listCreditTransactionsRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async listEmployersForCompanyRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/company/employers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListOtherEmployersForCompanyResultFromJSON(jsonValue));
    }
    /**
     */
    async listEmployersForCompany(initOverrides) {
        const response = await this.listEmployersForCompanyRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async listInvoicesForCompanyRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/invoice`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListInvoicesForCompanyResultFromJSON(jsonValue));
    }
    /**
     */
    async listInvoicesForCompany(initOverrides) {
        const response = await this.listInvoicesForCompanyRaw(initOverrides);
        return await response.value();
    }
    /**
     * List all job applications for a given job
     */
    async listJobApplicationsForJobRaw(requestParameters, initOverrides) {
        if (requestParameters['jobId'] == null) {
            throw new runtime.RequiredError('jobId', 'Required parameter "jobId" was null or undefined when calling listJobApplicationsForJob().');
        }
        const queryParameters = {};
        if (requestParameters['status'] != null) {
            queryParameters['status'] = requestParameters['status'];
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/job/{jobId}/job-applications`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters['jobId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListJobApplicationsForJobResultFromJSON(jsonValue));
    }
    /**
     * List all job applications for a given job
     */
    async listJobApplicationsForJob(jobId, status, initOverrides) {
        const response = await this.listJobApplicationsForJobRaw({ jobId: jobId, status: status }, initOverrides);
        return await response.value();
    }
    /**
     * List all jobs for the current company
     */
    async listJobsRaw(requestParameters, initOverrides) {
        if (requestParameters['showClosed'] == null) {
            throw new runtime.RequiredError('showClosed', 'Required parameter "showClosed" was null or undefined when calling listJobs().');
        }
        if (requestParameters['showDraft'] == null) {
            throw new runtime.RequiredError('showDraft', 'Required parameter "showDraft" was null or undefined when calling listJobs().');
        }
        const queryParameters = {};
        if (requestParameters['showClosed'] != null) {
            queryParameters['showClosed'] = requestParameters['showClosed'];
        }
        if (requestParameters['showDraft'] != null) {
            queryParameters['showDraft'] = requestParameters['showDraft'];
        }
        if (requestParameters['limit'] != null) {
            queryParameters['Limit'] = requestParameters['limit'];
        }
        if (requestParameters['offset'] != null) {
            queryParameters['Offset'] = requestParameters['offset'];
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/job`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListJobsResultFromJSON(jsonValue));
    }
    /**
     * List all jobs for the current company
     */
    async listJobs(showClosed, showDraft, limit, offset, initOverrides) {
        const response = await this.listJobsRaw({ showClosed: showClosed, showDraft: showDraft, limit: limit, offset: offset }, initOverrides);
        return await response.value();
    }
    /**
     * List the companies that an employer is allowed to manage
     */
    async listManagedCompaniesRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/company-admin`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListManagedCompaniesResultFromJSON(jsonValue));
    }
    /**
     * List the companies that an employer is allowed to manage
     */
    async listManagedCompanies(initOverrides) {
        const response = await this.listManagedCompaniesRaw(initOverrides);
        return await response.value();
    }
    /**
     * List matches for a given job
     */
    async listMatchesForJobRaw(requestParameters, initOverrides) {
        if (requestParameters['jobId'] == null) {
            throw new runtime.RequiredError('jobId', 'Required parameter "jobId" was null or undefined when calling listMatchesForJob().');
        }
        if (requestParameters['showClosed'] == null) {
            throw new runtime.RequiredError('showClosed', 'Required parameter "showClosed" was null or undefined when calling listMatchesForJob().');
        }
        const queryParameters = {};
        if (requestParameters['showClosed'] != null) {
            queryParameters['showClosed'] = requestParameters['showClosed'];
        }
        if (requestParameters['limit'] != null) {
            queryParameters['Limit'] = requestParameters['limit'];
        }
        if (requestParameters['offset'] != null) {
            queryParameters['Offset'] = requestParameters['offset'];
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/job/{jobId}/matches`.replace(`{${"jobId"}}`, encodeURIComponent(String(requestParameters['jobId']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListMatchesForJobResultFromJSON(jsonValue));
    }
    /**
     * List matches for a given job
     */
    async listMatchesForJob(jobId, showClosed, limit, offset, initOverrides) {
        const response = await this.listMatchesForJobRaw({ jobId: jobId, showClosed: showClosed, limit: limit, offset: offset }, initOverrides);
        return await response.value();
    }
    /**
     * List Payment options.  Contains subscriptions and credit packages.
     */
    async listPaymentOptionsRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/user/employer-onboarding/payment-options`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListPaymentOptionsResultFromJSON(jsonValue));
    }
    /**
     * List Payment options.  Contains subscriptions and credit packages.
     */
    async listPaymentOptions(initOverrides) {
        const response = await this.listPaymentOptionsRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async listReferralInvitesRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/admin/invite/list-referral-invites`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListReferralInvitesResultFromJSON(jsonValue));
    }
    /**
     */
    async listReferralInvites(initOverrides) {
        const response = await this.listReferralInvitesRaw(initOverrides);
        return await response.value();
    }
    /**
     * List questions and answers for subscriptions.
     */
    async listSubscriptionQuestionAnswersRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/user/employer-onboarding/question-answers`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListSubscriptionQuestionAnswersResultFromJSON(jsonValue));
    }
    /**
     * List questions and answers for subscriptions.
     */
    async listSubscriptionQuestionAnswers(initOverrides) {
        const response = await this.listSubscriptionQuestionAnswersRaw(initOverrides);
        return await response.value();
    }
    /**
     */
    async listUnhandledJobsRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters['limit'] != null) {
            queryParameters['Limit'] = requestParameters['limit'];
        }
        if (requestParameters['offset'] != null) {
            queryParameters['Offset'] = requestParameters['offset'];
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/dashboard/unhandled-jobs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListUnhandledJobsResultFromJSON(jsonValue));
    }
    /**
     */
    async listUnhandledJobs(limit, offset, initOverrides) {
        const response = await this.listUnhandledJobsRaw({ limit: limit, offset: offset }, initOverrides);
        return await response.value();
    }
    /**
     * List all unhandled matches for the current company
     */
    async listUnhandledMatchesRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters['limit'] != null) {
            queryParameters['Limit'] = requestParameters['limit'];
        }
        if (requestParameters['offset'] != null) {
            queryParameters['Offset'] = requestParameters['offset'];
        }
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/dashboard/unhandled-matches`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListUnhandledMatchesResultFromJSON(jsonValue));
    }
    /**
     * List all unhandled matches for the current company
     */
    async listUnhandledMatches(limit, offset, initOverrides) {
        const response = await this.listUnhandledMatchesRaw({ limit: limit, offset: offset }, initOverrides);
        return await response.value();
    }
    /**
     * An employer reacts to the best found candidate.  The employee will get notified that the Employer is interested in the Employee\'s profile.
     */
    async reactToBestCandidateRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/job/react-best-candidate`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: ReactToCandidateCommandDtoToJSON(requestParameters['reactToCandidateCommandDto']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     * An employer reacts to the best found candidate.  The employee will get notified that the Employer is interested in the Employee\'s profile.
     */
    async reactToBestCandidate(reactToCandidateCommandDto, initOverrides) {
        const response = await this.reactToBestCandidateRaw({ reactToCandidateCommandDto: reactToCandidateCommandDto }, initOverrides);
        return await response.value();
    }
    /**
     * An employer rejects a job application
     */
    async rejectJobApplicationRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/job-application/reject`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters['body'],
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => RejectJobApplicationResultFromJSON(jsonValue));
    }
    /**
     * An employer rejects a job application
     */
    async rejectJobApplication(body, initOverrides) {
        const response = await this.rejectJobApplicationRaw({ body: body }, initOverrides);
        return await response.value();
    }
    /**
     * Reset the company of an employer.
     */
    async resetManagedCompanyRaw(initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/company-admin/reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Reset the company of an employer.
     */
    async resetManagedCompany(initOverrides) {
        await this.resetManagedCompanyRaw(initOverrides);
    }
    /**
     * Send a Complete Profile Request to the anonymous user.
     */
    async sendCompleteProfileRequestRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/job-application/complete-profile-request`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendCompleteProfileRequestCommandDtoToJSON(requestParameters['sendCompleteProfileRequestCommandDto']),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Send a Complete Profile Request to the anonymous user.
     */
    async sendCompleteProfileRequest(sendCompleteProfileRequestCommandDto, initOverrides) {
        await this.sendCompleteProfileRequestRaw({ sendCompleteProfileRequestCommandDto: sendCompleteProfileRequestCommandDto }, initOverrides);
    }
    /**
     */
    async sendMessageForEmployerRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/match/chat`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendMessageCommandDtoToJSON(requestParameters['sendMessageCommandDto']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SendMessageResponseFromJSON(jsonValue));
    }
    /**
     */
    async sendMessageForEmployer(sendMessageCommandDto, initOverrides) {
        const response = await this.sendMessageForEmployerRaw({ sendMessageCommandDto: sendMessageCommandDto }, initOverrides);
        return await response.value();
    }
    /**
     */
    async sendPollRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/match/poll`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SendPollCommandDtoToJSON(requestParameters['sendPollCommandDto']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SendPollResultFromJSON(jsonValue));
    }
    /**
     */
    async sendPoll(sendPollCommandDto, initOverrides) {
        const response = await this.sendPollRaw({ sendPollCommandDto: sendPollCommandDto }, initOverrides);
        return await response.value();
    }
    /**
     * An employer starts a match for a job application
     */
    async startMatchRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/job-application/start-match`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StartMatchCommandDtoToJSON(requestParameters['startMatchCommandDto']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => StartMatchResultFromJSON(jsonValue));
    }
    /**
     * An employer starts a match for a job application
     */
    async startMatch(startMatchCommandDto, initOverrides) {
        const response = await this.startMatchRaw({ startMatchCommandDto: startMatchCommandDto }, initOverrides);
        return await response.value();
    }
    /**
     * Subscribe / unsubscribe to a job
     */
    async subscribeToJobRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/job/subscribe`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SubscribeToJobCommandDtoToJSON(requestParameters['subscribeToJobCommandDto']),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Subscribe / unsubscribe to a job
     */
    async subscribeToJob(subscribeToJobCommandDto, initOverrides) {
        await this.subscribeToJobRaw({ subscribeToJobCommandDto: subscribeToJobCommandDto }, initOverrides);
    }
    /**
     * Update a job
     */
    async updateJobRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/job`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateJobCommandDtoToJSON(requestParameters['updateJobCommandDto']),
        }, initOverrides);
        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse(response);
        }
        else {
            return new runtime.TextApiResponse(response);
        }
    }
    /**
     * Update a job
     */
    async updateJob(updateJobCommandDto, initOverrides) {
        const response = await this.updateJobRaw({ updateJobCommandDto: updateJobCommandDto }, initOverrides);
        return await response.value();
    }
    /**
     * Update the published status of a job
     */
    async updateJobPublishedRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/job/update-published`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateJobPublishedCommandDtoToJSON(requestParameters['updateJobPublishedCommandDto']),
        }, initOverrides);
        return new runtime.JSONApiResponse(response);
    }
    /**
     * Update the published status of a job
     */
    async updateJobPublished(updateJobPublishedCommandDto, initOverrides) {
        const response = await this.updateJobPublishedRaw({ updateJobPublishedCommandDto: updateJobPublishedCommandDto }, initOverrides);
        return await response.value();
    }
    /**
     * Update the company that an employer manages.
     */
    async updateManagedCompanyRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/company-admin/update`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateManagedCompanyCommandDtoToJSON(requestParameters['updateManagedCompanyCommandDto']),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Update the company that an employer manages.
     */
    async updateManagedCompany(updateManagedCompanyCommandDto, initOverrides) {
        await this.updateManagedCompanyRaw({ updateManagedCompanyCommandDto: updateManagedCompanyCommandDto }, initOverrides);
    }
    /**
     */
    async updateMyCompanyRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/company`,
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateCompanyCommandDtoToJSON(requestParameters['updateCompanyCommandDto']),
        }, initOverrides);
        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse(response);
        }
        else {
            return new runtime.TextApiResponse(response);
        }
    }
    /**
     */
    async updateMyCompany(updateCompanyCommandDto, initOverrides) {
        const response = await this.updateMyCompanyRaw({ updateCompanyCommandDto: updateCompanyCommandDto }, initOverrides);
        return await response.value();
    }
    /**
     */
    async updateProfileRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const response = await this.request({
            path: `/employer/profile`,
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateEmployerProfileCommandDtoToJSON(requestParameters['updateEmployerProfileCommandDto']),
        }, initOverrides);
        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse(response);
        }
        else {
            return new runtime.TextApiResponse(response);
        }
    }
    /**
     */
    async updateProfile(updateEmployerProfileCommandDto, initOverrides) {
        const response = await this.updateProfileRaw({ updateEmployerProfileCommandDto: updateEmployerProfileCommandDto }, initOverrides);
        return await response.value();
    }
    /**
     * Upload Provisional Company Image
     */
    async uploadProvisionalImageRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = await this.configuration.apiKey("Authorization"); // Bearer authentication
        }
        const consumes = [
            { contentType: 'multipart/form-data' },
        ];
        // @ts-ignore: canConsumeForm may be unused
        const canConsumeForm = runtime.canConsumeForm(consumes);
        let formParams;
        let useForm = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        }
        else {
            formParams = new URLSearchParams();
        }
        if (requestParameters['file'] != null) {
            formParams.append('File', requestParameters['file']);
        }
        const response = await this.request({
            path: `/employer/company/provisional-image`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: formParams,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ImageDtoFromJSON(jsonValue));
    }
    /**
     * Upload Provisional Company Image
     */
    async uploadProvisionalImage(file, initOverrides) {
        const response = await this.uploadProvisionalImageRaw({ file: file }, initOverrides);
        return await response.value();
    }
}
