/* tslint:disable */
/* eslint-disable */
/**
 * Swipe4Work Employer API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.1.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/**
 * Check if a given object implements the JobDescriptionDto interface.
 */
export function instanceOfJobDescriptionDto(value) {
    if (!('title' in value) || value['title'] === undefined)
        return false;
    if (!('duties' in value) || value['duties'] === undefined)
        return false;
    if (!('requirements' in value) || value['requirements'] === undefined)
        return false;
    if (!('benefitsAndPerks' in value) || value['benefitsAndPerks'] === undefined)
        return false;
    return true;
}
export function JobDescriptionDtoFromJSON(json) {
    return JobDescriptionDtoFromJSONTyped(json, false);
}
export function JobDescriptionDtoFromJSONTyped(json, ignoreDiscriminator) {
    if (json == null) {
        return json;
    }
    return {
        'title': json['title'],
        'duties': json['duties'],
        'requirements': json['requirements'],
        'benefitsAndPerks': json['benefitsAndPerks'],
    };
}
export function JobDescriptionDtoToJSON(value) {
    if (value == null) {
        return value;
    }
    return {
        'title': value['title'],
        'duties': value['duties'],
        'requirements': value['requirements'],
        'benefitsAndPerks': value['benefitsAndPerks'],
    };
}
