import { Role } from '@swipe4work/api-client-fetch'
import { useAuthStore } from '@/store'
import type { UserModule } from '@/types'

export const install: UserModule = (_, router) => {
  // Route guards
  router.beforeEach(async (to) => {
    const authStore = useAuthStore()
    const currentUser = await authStore.getCurrentUser()
    if (!currentUser) {
      if (!to.meta.allowAnonymous) {
        return {
          name: 'login',
        }
      }
      return
    }
    await authStore.getRole()
    // None should not happen, since an employer is either invited by email (and the user becomes an onboarding Employer),
    // Or the user opened the app and a User account is created.
    if (authStore.isEmployeeUser || authStore.role == Role.None) {
      // Not allowed to access the application as a normal user
      if (to.name != 'employee') {
        return {
          name: 'employee',
        }
      }
      return
    }
    if (!to.meta.isOnboarding && authStore.role == Role.OnboardingEmployer) {
      return {
        name: 'onboarding-company',
      }
    }
    if (to.meta.isOnboarding && authStore.role == Role.Employer) {
      return {
        name: 'index',
      }
    }
    if (to.name == 'employee') {
      return {
        name: 'index',
      }
    }
  })
}
